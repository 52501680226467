import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ViewContent from './routes/ViewContent';
// import AndroidPage from './pages/Android';
// import IOSPage from './pages/iOS';
import ContactPage from './pages/Contact';
import DownloadPage from './pages/Download';
import FAQPage from './pages/FAQ';
import InstagramPage from './pages/Instagram';
import LinkedInPage from './pages/LinkedIn';
import PolicyPage from './pages/Policy';
import TermsPage from './pages/TermsPage';
import DeleteRequest from './pages/DeleteRequest'
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/terms" element={<TermsPage />} />

      {/* Add routes for your new pages here */}
      {/* <Route path="/android" element={<AndroidPage />} /> */}
      {/* <Route path="/ios" element={<IOSPage />} /> */}
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/download" element={<DownloadPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/instagram" element={<InstagramPage />} />
      <Route path="/linkedin" element={<LinkedInPage />} />
      <Route path="/policy" element={<PolicyPage />} />
      <Route path="/delete-request" element={<DeleteRequest />} />
      <Route path="content" element={<ViewContent />}>
        <Route path=":contentId" element={<ViewContent />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
