import React, { useRef, useEffect, useState } from 'react';
import HomeImage from './pages/Home.png';
import HomeImageMobile from './pages/HomeMobile.png';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC08qIalF4EFG_L3_vjG8XmlAh8Gj8C7UE",
  authDomain: "laffbag-5c814.firebaseapp.com",
  projectId: "laffbag-5c814",
  storageBucket: "laffbag-5c814.appspot.com",
  messagingSenderId: "426398261879",
  appId: "1:426398261879:web:23013eafec5d31e8719c72",
  measurementId: "G-V50CH465D8"
};

const App = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const imgRef = useRef();
  const [imgRect, setImgRect] = useState();

  useEffect(() => {
    const handleResize = () => {
      setImgRect(imgRef.current.getBoundingClientRect());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = window.innerWidth <= 768;

  const Button = ({ onClick, top, left, width, height, mobileTop, mobileLeft, mobileWidth, mobileHeight }) => (
    <div
      onClick={onClick}
      style={{
        position: 'absolute',
        top: imgRect ? imgRect.top + imgRect.height * (isMobile ? mobileTop : top) : 0,
        left: imgRect ? imgRect.left + imgRect.width * (isMobile ? mobileLeft : left) : 0,
        width: imgRect ? imgRect.width * (isMobile ? mobileWidth : width) : 0,
        height: imgRect ? imgRect.height * (isMobile ? mobileHeight : height) : 0,
        cursor: 'pointer',
        //border: '2px solid red'
      }}
    />
  );

  let navigate = useNavigate();

  // const handleClickAndroid = () => {
  //   // console.log('Android button clicked');
  //   //navigate("/android");
  //   // window.location.href = "https://www.TBC";
  // };
  
  // const handleClickIOS = () => {
  //   // console.log('iOS button clicked');
  //   //navigate("/ios");
  //   // window.location.href = "https://www.TBC";
  // };
  
  const handleClickTerms = () => {
    // console.log('Terms button clicked');
    navigate("/terms");
  };
  
  const handleClickPolicy = () => {
    // console.log('Policy button clicked');
    navigate("/policy");
  };
  
  const handleClickContact = () => {
    // console.log('Contact button clicked');
    navigate("/contact");
  };
  
  const handleClickEula = () => {
    // console.log('Download button clicked');
    //navigate("/download");
    window.location.href = "/eula.html";
  };
  
  const handleClickInstagram = () => {
    // console.log('Instagram button clicked');
    //navigate("/instagram");
    // window.location.href = "https://www.TBC";
  };
  
  const handleClickLinkedin = () => {
    // console.log('Linkedin button clicked');
   // navigate("/linkedin");
   // window.location.href = "https://www.TBC";
  };

  return (
    <div style={{ position: 'relative', overflow: 'hidden', height: '100vh', width: '100vw' }}>
      <img ref={imgRef} src={isMobile ? HomeImageMobile : HomeImage} onLoad={() => setImgRect(imgRef.current.getBoundingClientRect())} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', objectFit: 'contain', maxHeight: '100vh', maxWidth: '100vw' }} alt="Home" />
      {/* <Button onClick={handleClickAndroid} top={0.72} left={0.678} width={0.135} height={0.07} mobileTop={0.748} mobileLeft={0.52} mobileWidth={0.33} mobileHeight={0.04} /> */}
      {/* <Button onClick={handleClickIOS} top={0.72} left={0.515} width={0.136} height={0.07} mobileTop={0.748} mobileLeft={0.12} mobileWidth={0.33} mobileHeight={0.04} /> */}
      {/* <Button onClick={handleClickDownload} top={0.90} left={0.695} width={0.152} height={0.04} mobileTop={0.957} mobileLeft={0.48} mobileWidth={0.325} mobileHeight={0.015} /> */}
      <Button onClick={handleClickTerms} top={0.90} left={0.15} width={0.195} height={0.04} mobileTop={0.925} mobileLeft={0.11} mobileWidth={0.42} mobileHeight={0.015} />
      <Button onClick={handleClickPolicy} top={0.90} left={0.38} width={0.139} height={0.04} mobileTop={0.925} mobileLeft={0.58} mobileWidth={0.30} mobileHeight={0.015} />
      <Button onClick={handleClickContact} top={0.90} left={0.55} width={0.11} height={0.04} mobileTop={0.957} mobileLeft={0.18} mobileWidth={0.25} mobileHeight={0.015} />
      <Button onClick={handleClickEula} top={0.90} left={0.70} width={0.09} height={0.04} mobileTop={0.957} mobileLeft={0.48} mobileWidth={0.20} mobileHeight={0.015} />
      <Button onClick={handleClickInstagram} top={0.71} left={0.844} width={0.045} height={0.082} mobileTop={0.815} mobileLeft={0.34} mobileWidth={0.125} mobileHeight={0.06} />
      <Button onClick={handleClickLinkedin} top={0.71} left={0.905} width={0.047} height={0.082} mobileTop={0.815} mobileLeft={0.52} mobileWidth={0.125} mobileHeight={0.06} />
    </div>
  );
};

export default App;
