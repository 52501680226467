import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { ReactComponent as SvgLogo } from "../logo/new LAFFBAG.svg";
import '../styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.message) formErrors.message = "Message is required";
    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const sendEmail = () => {
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs.send("service_mjymkt8", 'template_mvqc1sw', templateParams, "PnBieaQ0Gd6vqZK5U")
      .then(() => {
        setSubmitted(true);
      }, () => {
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      sendEmail();
    } else {
      setErrors(formErrors);
    }
  };

  const inputStyle = {
    padding: '10px',
    margin: '10px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box'
  };

  const buttonStyle = {
    padding: '12px',
    backgroundColor: '#E31F4B',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: "bold",
    marginTop: '10px'
  };

  const errorStyle = {
    color: 'red',
    fontSize: '14px',
    marginTop: '5px'
  };

  return (
    <div class={'container'}>
      <div class={'left-container'}>
        <h2 style={{ color: "#624A9F", fontSize: '28px', textAlign: 'center', width: '100%' }}>Contact Us</h2>
        <p style={{ color: '#666', textAlign: 'center' }}>
          Need to get in touch with us? Fill in the form, and we’ll get back to you as soon as possible!
        </p>
        <SvgLogo style={{ width: '150px', height: 'auto', marginTop: '10px' }} />

      </div>

      {submitted ? (
        <h2>Thank you for your message!</h2>
      ) : (
        <form onSubmit={handleSubmit} class={'form-style'}>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={inputStyle}
            />
            {errors.name && <p style={errorStyle}>{errors.name}</p>}
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={inputStyle}
            />
            {errors.email && <p style={errorStyle}>{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              style={{ ...inputStyle, height: '100px' }}
            />
            {errors.message && <p style={errorStyle}>{errors.message}</p>}
          </div>
          <button type="submit" style={buttonStyle}>Submit</button>
        </form>
      )}
    </div>
  );
};

export default Contact;
